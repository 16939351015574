import React, { useState } from "react";
import Navbar from "./components/navbar";
import Hero from "./components/hero"
import DatingApplication from "./components/datingApplication"
import ContactUs from "./components/contactUs"
import Privacy from "./components/Privacy"

function App() {
  const [activeTab, setActiveTab] = useState("Hero");

  const renderContent = () => {
    switch (activeTab) {
      case "Hero":
        return <Hero />;
      case "Amor":
        return <DatingApplication setActiveTab={setActiveTab} />;
      case "Contact":
        return <ContactUs />;
      case "Privacy":
        return <Privacy />;
      default:
        return <Hero />;
    }
  };

  return (
    <div>
      <Navbar activeTab={activeTab} setActiveTab={setActiveTab} />
      {renderContent()}
    </div>
  );
}

export default App;
