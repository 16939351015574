import React from "react";

const Privacy = () => {
    return (
        <div className="w-full bg-white py-16 px-4">
            <div className="max-w-[1240px] mx-auto">
                <div className="flex flex-col justify-center">
                    <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">Privacy Statement</h1>
                    <p className="text-gray-600 mb-4">Effective Date: 12/02/2024</p>
                    
                    <p className="mb-8">
                        SEInsights values your privacy and is committed to protecting your personal information. 
                        This Privacy Statement explains how we collect, use, disclose, and safeguard your information when you use our web application "Amor AI". 
                        Please read this Privacy Statement carefully to understand our views and practices regarding your information and how we will treat it.
                    </p>

                    {/* Section 1 */}
                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">1. Information We Collect</h2>
                        <p className="mb-2">We may collect and process the following types of information:</p>
                        
                        <h3 className="font-semibold mb-2">a. Information You Provide Directly</h3>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Personal details such as name, email address, and any other information you voluntarily provide when creating an account or interacting with the Application.</li>
                        </ul>

                        <h3 className="font-semibold mb-2">b. Automatically Collected Information</h3>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Usage Data: Information about how you use the Application, such as pages viewed, features accessed, and the time and duration of your activities.</li>
                            <li>Device Data: Information about your device, including IP address, browser type, operating system, and device identifiers.</li>
                            <li>Cookies and Tracking Technologies: Information collected via cookies, beacons, and similar technologies.</li>
                        </ul>

                        <h3 className="font-semibold mb-2">c. Information Processed by Gemini</h3>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Text Inputs: Certain text-based inputs or user queries may be sent to Gemini, an AI service provided by Google DeepMind, to deliver specific features or functionality (e.g., generating responses, suggestions, or recommendations).</li>
                            <li>Images: Images uploaded or shared within the Application may also be transmitted to Gemini for processing. These images are used solely to provide requested functionality, such as image analysis, annotations, or content generation, and are not stored beyond the duration necessary for processing.</li>
                        </ul>
                    </div>

                    {/* Section 2 */}
                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">2. How We Use Your Information</h2>
                        <p>We use your information to:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Provide and maintain the Application.</li>
                            <li>Process interactions and generate responses using Gemini.</li>
                            <li>Analyze and process images for functionality via Gemini.</li>
                            <li>Personalize your experience.</li>
                            <li>Communicate with you, including sending notifications, updates, or support messages.</li>
                            <li>Improve the Application by analyzing usage patterns and feedback.</li>
                            <li>Ensure compliance with legal obligations.</li>
                        </ul>
                    </div>

                    {/* Section 3 */}
                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">3. Sharing Your Information</h2>
                        <p className="mb-4">We do not sell or rent your personal information. However, we may share your information as follows:</p>

                        <h3 className="font-semibold mb-2">a. Gemini Integration</h3>
                        <p className="mb-4">Certain user inputs, including text and images, may be sent to Gemini for processing to enable the Application's features. The information sent to Gemini is governed by Google DeepMind's Privacy Policy. While we take steps to limit the sharing of identifiable information, please avoid submitting sensitive or confidential content unless required for the functionality of the Application.</p>

                        <h3 className="font-semibold mb-2">b. Service Providers</h3>
                        <p className="mb-4">We may share your information with trusted third-party companies that assist us in delivering the Application (e.g., hosting, analytics, or payment processing).</p>

                        <h3 className="font-semibold mb-2">c. Legal Compliance</h3>
                        <p className="mb-4">We may share information with authorities as required by law or to protect our legal rights.</p>

                        <h3 className="font-semibold mb-2">d. Business Transfers</h3>
                        <p className="mb-4">In case of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.</p>
                    </div>

                    {/* Sections 4-10 */}
                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">4. Your Rights and Choices</h2>
                        <p>You have the right to:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Access, correct, or delete your personal information.</li>
                            <li>Opt-out of certain data-sharing practices, including the use of Gemini for processing.</li>
                            <li>Disable cookies in your browser settings.</li>
                            <li>Withdraw consent where applicable.</li>
                        </ul>
                        <p>To exercise these rights, contact us at seinsights1998@gmail.com.</p>
                    </div>

                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">5. Data Security</h2>
                        <p>We implement industry-standard security measures to protect your information. However, no system is completely secure, and we cannot guarantee the absolute security of your data.</p>
                    </div>

                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">6. Retention of Information</h2>
                        <p>We retain your information only for as long as necessary to fulfill the purposes outlined in this Privacy Statement, comply with legal obligations, or resolve disputes.</p>
                    </div>

                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">7. Use of AI Services (Gemini by Google DeepMind)</h2>
                        <p className="mb-4">The Application integrates Gemini, a service provided by Google DeepMind, to enhance user experience. When you use features that interact with Gemini:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Text Inputs: Your inputs may be transmitted to Google DeepMind's servers for processing to deliver requested functionalities.</li>
                            <li>Images: Any images uploaded or shared may also be transmitted to Google DeepMind for analysis or content generation. Google DeepMind's processing of these inputs is governed by their Privacy Policy.</li>
                            <li>Sensitive Data: We recommend avoiding the submission of sensitive personal or confidential text or images unless required for specific Application functionality.</li>
                        </ul>
                    </div>

                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">8. International Users</h2>
                        <p>If you access the Application outside of the United States, please note that your data may be transferred to, stored, and processed in a country with different data protection laws.</p>
                    </div>

                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">9. Changes to This Privacy Statement</h2>
                        <p>We may update this Privacy Statement from time to time. We will notify you of any changes by updating the "Effective Date" above and, where appropriate, providing additional notice.</p>
                    </div>

                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">10. Contact Us</h2>
                        <p>If you have questions or concerns about this Privacy Statement or our practices, please contact us at:</p>
                        <p className="mt-4">
                            SEInsights<br />
                            Email: seinsights1998@gmail.com
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy;