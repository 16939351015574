import React, { useState } from "react"
import {AiOutlineClose, AiOutlineMenu} from "react-icons/ai"

const Navbar = ({ activeTab, setActiveTab }) => {
    const [nav, setNav] = useState(false)

    const handleNav = ()=> {
        setNav(!nav)
    }

    return (
        <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
            <h1 className='w-full text-3xl font-bold text-[#00df9a]'>SEInsights</h1>
            <ul className="hidden md:flex">
                <li className={`p-4 cursor-pointer ${activeTab === "Hero" ? "text-[#00df9a]" : ""}`}>
                    <button onClick={() => setActiveTab("Hero")}>Home</button>
                </li>
                <li className={`p-4 cursor-pointer ${activeTab === "Amor" ? "text-[#00df9a]" : ""}`}>
                    <button onClick={() => setActiveTab("Amor")}>Amor</button>
                </li>
                <li className={`p-4 cursor-pointer ${activeTab === "Contact" ? "text-[#00df9a]" : ""}`}>
                    <button onClick={() => setActiveTab("Contact")}>Contact</button>
                </li>
            </ul>
            <div onClick={handleNav} className="block md:hidden">
                {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
            </div>
            <div className={nav ? "fixed left-0 top-0 w-[60%] h-full border-r-gray-900 bg-[#000300] ease-in-out duration-500" : "fixed left-[-100%]"}>
                <h1 className='w-full text-3xl font-bold text-[#00df9a] m-4'>SEInsights</h1>
                <ul className="uppercase p-4">
                    <li className="p-4 border-b border-gray-600 cursor-pointer" onClick={() => setActiveTab("Hero")}>Home</li>
                    <li className="p-4 border-b border-gray-600 cursor-pointer" onClick={() => setActiveTab("Amor")}>Amor</li>
                    <li className="p-4" onClick={() => setActiveTab("Contact")}>Contact</li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar